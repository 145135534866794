<template>
  <div>
    <List
      title="Roles"
      resource="customerRoles"
      :basePath="basePath"
      :fields="fields"
      :request-params="{customerId: customerId, deletedOnly: true}"
      ref="list"
      track-by="roleId"
      on-row-click="details"
      infinity-scroll
      :initial-filter="filter"
      :search="true"
      search-placeholder="Search for a role by name or description"
      :filter-mapper="mapFilterToQuery"
    >
      <template v-slot:filters="props">
        <div class="form-row">
          <div class="form-col">
            <SelectInput
              name="appId"
              label="Application"
              :options="apps"
            />
          </div>
        </div>
        <div class="form-row justify-end">
          <button @click.stop="props.reset()" class="btn-transparent mr-8">Reset</button>
          <button class="btn-primary">Filter</button>
        </div>
      </template>
    </List>
  </div>
</template>

<script>
import List from '@/components/auth/list/List';
import ModalNavigation from '@/mixins/ModalNavigation';
import SelectInput from '@/components/form/SelectInput';
import ModalFooterMixin from "@/components/ui/modals/ModalFooterMixin";

export default {
    name: 'DeletedRolesList',
    components: {
      SelectInput,
      List,
    },
    mixins: [ModalNavigation, ModalFooterMixin],
    computed: {
      basePath: function () {
        return this.$route.path;
      },
      customerId: function () {
        return this.$route.params.customerId;
      }
    },
    data() {
      return {
        apps: [],
        filter: {
          appId: null,
        },
        fields: [
          {
            name: 'type',
            sortField: 'type',
            title: 'Role Type',
          },
          {
            name: 'appName',
            sortField: 'appName',
            title: 'Application',
          },
          {
            name: 'alias',
            sortField: 'alias',
            title: 'Role Name',
          },
          {
            name: 'roleId',
            sortField: 'roleId',
            title: 'Role ID',
          },
          {
            name: 'description',
            title: 'Role Description',
          },
        ],
      };
    },
    created() {
      this.$authDataProvider.getList('customerApps', {customerId: this.customerId})
        .then(apps => {
          this.apps = [
            {key: null, value: 'All'},
            ...apps.filter(app => !app.isCustomerUserAgnostic)
              .map(({name: value, id: key}) => ({
                key,
                value
              }))
          ];
        })
        .catch((err) => console.log(err));
    },
    methods: {
      mapFilterToQuery(values) {
        return Object.keys(values).reduce((query, key) => {
          const value = values[key];

          if (value) {
            query[key] = value;
          }

          return query;
        }, {});
      },
    }
  };
</script>

<style scoped>
  #modal-footer {
    justify-content: flex-end;
  }
</style>
